import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pageexpired',
  templateUrl: './pageexpired.component.html',
  styleUrls: ['./pageexpired.component.css']
})
export class PageexpiredComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
