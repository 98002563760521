import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aboutwomen',
  templateUrl: './aboutwomen.component.html',
  styleUrls: ['./aboutwomen.component.css']
})
export class AboutwomenComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
